import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { BehaviorSubject, Observable, tap } from "rxjs";
import {
  IAccount,
  IBill,
  IBillItem,
  IBillPayment,
  IBillPaymentResponse,
  IBillResponse,
  ICharge,
  IChargeResponse,
  ICheck,
  ICheckResponse,
  ICreditMemo,
  ICreditMemoResponse,
  IDeposit,
  IDepositResponse,
  IInvoice,
  IInvoiceResponse,
  IItem,
  IJournalEntry,
  IJournalEntryResponse,
  IPaymentMethod,
  IReceivedPayment,
  IReceivedPaymentResponse,
  ITerm,
  ITransfer,
  ITransferResponse,
  IVendorCredit,
  IVendorCreditResponse,
} from "../interfaces/accounting.interface";
import { ICustomer } from "../interfaces/customer.interface";
import { IResponse, IResponseDTO } from "@shared/interfaces";

@Injectable({
  providedIn: "root",
})
export class AccountingService {
  private apiUrl = environment.apiUrl;
  private _terms$: BehaviorSubject<ITerm[] | any> = new BehaviorSubject(null);
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this._loading$.asObservable();
  public terms$ = this._terms$.asObservable();
  public refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private http: HttpClient) {}

  // Lists
  public getCustomers(
    property: string,
    all: boolean = false
  ): Observable<IResponseDTO<ICustomer[]>> {
    let url = `${this.apiUrl}/customer-accounts`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponseDTO<ICustomer[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }
  public getItems(
    property: string,
    all: boolean = false
  ): Observable<IResponseDTO<IItem[]>> {
    console.log("property", property, "all", all);
    let url = `${this.apiUrl}/items`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponseDTO<IItem[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  getItemsByPropertyId(propertyId: string) {
    return this.http.get<any>(`${this.apiUrl}/items/property/${propertyId}`);
  }

  public getPaymentMethods(
    property: string,
    all: boolean = false
  ): Observable<IResponseDTO<IPaymentMethod[]>> {
    let url = `${this.apiUrl}/payment-methods`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponseDTO<IPaymentMethod[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }
  public getTerms(
    property: string,
    all: boolean = false
  ): Observable<IResponseDTO<ITerm[]>> {
    let url = `${this.apiUrl}/terms`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponseDTO<ITerm[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  getTermsByPropertyId(propertyId: string) {
    return this.http.get<any>(`${this.apiUrl}/terms/property/${propertyId}`);
  }

  // Accounts
  public getAccounts(
    property: string,
    all: boolean = false
  ): Observable<IResponse<IAccount[]>> {
    let url = `${this.apiUrl}/accounts`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponse<IAccount[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  public getIncomeExpenseAccounts(
    property: string,
    all: boolean = false
  ): Observable<IResponse<IAccount[]>> {
    let url = `${this.apiUrl}/accounts/income-expense`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponse<IAccount[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }
  public getExpenseAccounts(
    property: string,
    all: boolean = false
  ): Observable<IResponse<IAccount[]>> {
    let url = `${this.apiUrl}/accounts/expense`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponse<IAccount[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  public getIncomeAccounts(
    property: string,
    all: boolean = false
  ): Observable<IResponse<IAccount[]>> {
    let url = `${this.apiUrl}/accounts/income`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponse<IAccount[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  public getAccountReceivable(
    property: string,
    all: boolean = false
  ): Observable<IResponse<IAccount[]>> {
    let url = `${this.apiUrl}/accounts/accounts-receivable`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponse<IAccount[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  public getUndepositedFunds(
    property: string,
    all: boolean = false
  ): Observable<IResponse<IAccount[]>> {
    let url = `${this.apiUrl}/accounts/undeposited-funds`;
    if (all) {
      url += `/all`;
    }
    return this.http.get<IResponse<IAccount[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  // Bills
  public searchBills(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<IBill[]>>(`${this.apiUrl}/bills/search`, params)
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getBills(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<IBill[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<IBill[]>>(
        `${this.apiUrl}/bills/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
            manager,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getBill(id: string): Observable<IResponse<IBillResponse>> {
    return this.http.get<IResponse<IBillResponse>>(
      `${this.apiUrl}/bills/${id}`,
      { withCredentials: true }
    );
  }

  public createBill(body: IBillItem) {
    return this.http.post(`${this.apiUrl}/bills/`, body);
  }

  public editBill(id: string, body: IBillItem) {
    return this.http.patch(`${this.apiUrl}/bills/${id}`, body, {
      withCredentials: true,
    });
  }

  public deleteBill(id: string) {
    return this.http.delete(`${this.apiUrl}/bills/${id}`, {
      withCredentials: true,
    });
  }

  // Vendor Credits
  public searchVendorCredits(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<IVendorCredit[]>>(
        `${this.apiUrl}/vendor-credits/search`,
        {
          ...params,
          withCredentials: true,
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getVendorCredits(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<IVendorCredit[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<IVendorCredit[]>>(
        `${this.apiUrl}/vendor-credits/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
            manager,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getVendorCredit(
    id: string
  ): Observable<IResponse<IVendorCreditResponse>> {
    return this.http.get<IResponse<IVendorCreditResponse>>(
      `${this.apiUrl}/vendor-credits/${id}`,
      { withCredentials: true }
    );
  }
  public createVendorCredit(body: IVendorCredit) {
    return this.http.post(`${this.apiUrl}/vendor-credits/`, body);
  }
  public editVendorCredit(id: string, body: IVendorCredit) {
    return this.http.patch(`${this.apiUrl}/vendor-credits/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteVendorCredit(id: string) {
    return this.http.delete(`${this.apiUrl}/vendor-credits/${id}`, {
      withCredentials: true,
    });
  }

  public getVendorBills(property: string, vendorId: string): Observable<any> {
    const body = {
      vendor: vendorId,
      property: property,
    };
    return this.http.post<any>(`${this.apiUrl}/vendors/open-bills`, body, {
      withCredentials: true,
    });
  }

  // Bill Payments
  public searchBillPayments(params: any) {
    this._loading$.next(true);

    return this.http
      .post<
        IResponseDTO<IBillPayment[]>
      >(`${this.apiUrl}/bill-payments/search`, params)
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getBillPayments(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<IBillPayment[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<IBillPayment[]>>(
        `${this.apiUrl}/bill-payments/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
            manager,
          },
        }
      )
      .pipe(
        tap(_ => {
          this._loading$.next(false);
        })
      );
  }
  public getOpenBills(
    propertyId: string,
    vendorId: string
  ): Observable<IBill[]> {
    const body = {
      vendor: vendorId,
      property: propertyId,
    };
    return this.http.post<IBill[]>(`${this.apiUrl}/vendors/open-bills`, body, {
      withCredentials: true,
    });
  }
  public getOpenInvoices(customerId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/customers/${customerId}/open-invoices`,
      { withCredentials: true }
    );
  }
  public getBillPayment(
    id: string
  ): Observable<IResponse<IBillPaymentResponse>> {
    return this.http.get<IResponse<IBillPaymentResponse>>(
      `${this.apiUrl}/bill-payments/${id}`,
      { withCredentials: true }
    );
  }

  public createBillPayment(body: any) {
    return this.http.post(`${this.apiUrl}/bill-payments`, body, {
      withCredentials: true,
    });
  }

  public editBillPayment(id: string, body: { amount: number }) {
    return this.http.patch(`${this.apiUrl}/bill-payments/${id}`, body, {
      withCredentials: true,
    });
  }

  public deleteBillPayment(id: string) {
    return this.http.delete(`${this.apiUrl}/bill-payments/${id}`, {
      withCredentials: true,
    });
  }

  // Checks
  public searchChecks(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<ICheckResponse[]>>(`${this.apiUrl}/checks/search`, {
        ...params,
        withCredentials: true,
      })
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getChecks(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<ICheckResponse[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<ICheckResponse[]>>(
        `${this.apiUrl}/checks/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getCheck(id: string): Observable<IResponse<ICheckResponse>> {
    return this.http.get<IResponse<ICheckResponse>>(
      `${this.apiUrl}/checks/${id}`,
      { withCredentials: true }
    );
  }
  public createCheck(body: ICheck) {
    return this.http.post(`${this.apiUrl}/checks/`, body);
  }
  public editCheck(id: string, body: ICheck) {
    return this.http.patch(`${this.apiUrl}/checks/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteCheck(id: string) {
    return this.http.delete(`${this.apiUrl}/checks/${id}`, {
      withCredentials: true,
    });
  }

  // Charges
  public searchCharges(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<ICharge[]>>(`${this.apiUrl}/charges/search`, {
        ...params,
        withCredentials: true,
      })
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getCharges(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<IChargeResponse[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<IChargeResponse[]>>(
        `${this.apiUrl}/charges/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getCharge(id: string): Observable<IResponse<IChargeResponse>> {
    return this.http.get<IResponse<IChargeResponse>>(
      `${this.apiUrl}/charges/${id}`,
      { withCredentials: true }
    );
  }
  public createCharge(body: ICharge) {
    return this.http.post(`${this.apiUrl}/charges/`, body);
  }
  public editCharge(id: string, body: ICharge) {
    return this.http.patch(`${this.apiUrl}/charges/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteCharge(id: string) {
    return this.http.delete(`${this.apiUrl}/charges/${id}`, {
      withCredentials: true,
    });
  }

  // Credit Memos
  public searchCreditMemos(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<ICreditMemoResponse[]>>(
        `${this.apiUrl}/credit-memos/search`,
        {
          ...params,
          withCredentials: true,
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getCreditMemos(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<ICreditMemoResponse[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<ICreditMemoResponse[]>>(
        `${this.apiUrl}/credit-memos/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getCreditMemo(id: string): Observable<IResponse<ICreditMemoResponse>> {
    return this.http.get<IResponse<ICreditMemoResponse>>(
      `${this.apiUrl}/credit-memos/${id}`,
      { withCredentials: true }
    );
  }
  public createCreditMemo(body: ICreditMemo) {
    return this.http.post(`${this.apiUrl}/credit-memos/`, body);
  }
  public editCreditMemo(id: string, body: ICreditMemo) {
    return this.http.patch(`${this.apiUrl}/credit-memos/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteCreditMemo(id: string) {
    return this.http.delete(`${this.apiUrl}/credit-memos/${id}`, {
      withCredentials: true,
    });
  }

  // Deposits
  public searchDeposits(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<IDepositResponse[]>>(
        `${this.apiUrl}/deposits/search`,
        {
          ...params,
          withCredentials: true,
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getDeposits(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<IDepositResponse[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<IDepositResponse[]>>(
        `${this.apiUrl}/deposits/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getDeposit(id: string): Observable<IResponse<IDepositResponse>> {
    return this.http.get<IResponse<IDepositResponse>>(
      `${this.apiUrl}/deposits/${id}`,
      { withCredentials: true }
    );
  }
  public createDeposit(body: IDeposit) {
    return this.http.post(`${this.apiUrl}/deposits/`, body);
  }
  public editDeposit(id: string, body: IDeposit) {
    return this.http.patch(`${this.apiUrl}/deposits/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteDeposit(id: string) {
    return this.http.delete(`${this.apiUrl}/deposits/${id}`, {
      withCredentials: true,
    });
  }

  // Invoices
  public searchInvoices(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<IInvoiceResponse[]>>(
        `${this.apiUrl}/invoices/search`,
        {
          ...params,
          withCredentials: true,
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getInvoices(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<IInvoiceResponse[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<IInvoiceResponse[]>>(
        `${this.apiUrl}/invoices/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
            manager,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getInvoice(id: string): Observable<IResponse<IInvoiceResponse>> {
    return this.http.get<IResponse<IInvoiceResponse>>(
      `${this.apiUrl}/invoices/${id}`,
      { withCredentials: true }
    );
  }
  public createInvoice(body: IInvoice) {
    return this.http.post(`${this.apiUrl}/invoices/`, body);
  }
  public editInvoice(id: string, body: IInvoice) {
    return this.http.patch(`${this.apiUrl}/invoices/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteInvoice(id: string) {
    return this.http.delete(`${this.apiUrl}/invoices/${id}`, {
      withCredentials: true,
    });
  }

  // Received Payments
  public searchReceivedPayments(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<IReceivedPaymentResponse[]>>(
        `${this.apiUrl}/received-payments/search`,
        {
          ...params,
          withCredentials: true,
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getReceivedPayments(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<IReceivedPaymentResponse[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<IReceivedPaymentResponse[]>>(
        `${this.apiUrl}/received-payments/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getReceivedPayment(
    id: string
  ): Observable<IResponse<IReceivedPaymentResponse>> {
    return this.http.get<IResponse<IReceivedPaymentResponse>>(
      `${this.apiUrl}/received-payments/${id}`,
      { withCredentials: true }
    );
  }
  public createReceivedPayment(body: IReceivedPayment) {
    return this.http.post(`${this.apiUrl}/received-payments`, body);
  }
  public editReceivedPayment(id: string, body: IReceivedPayment) {
    return this.http.patch(`${this.apiUrl}/received-payments/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteReceivedPayment(id: string) {
    return this.http.delete(`${this.apiUrl}/received-payments/${id}`, {
      withCredentials: true,
    });
  }

  // Transfers
  public searchTransfer(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<ITransferResponse[]>>(
        `${this.apiUrl}/transfers/search`,
        {
          ...params,
          withCredentials: true,
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getTransfers(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<ITransferResponse[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<ITransferResponse[]>>(
        `${this.apiUrl}/transfers/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getTransfer(id: string): Observable<IResponse<ITransferResponse>> {
    return this.http.get<IResponse<ITransferResponse>>(
      `${this.apiUrl}/transfers/${id}`,
      { withCredentials: true }
    );
  }
  public createTransfer(body: ITransfer) {
    return this.http.post(`${this.apiUrl}/transfers/`, body);
  }
  public editTransfer(id: string, body: ITransfer) {
    return this.http.patch(`${this.apiUrl}/transfers/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteTransfer(id: string) {
    return this.http.delete(`${this.apiUrl}/transfers/${id}`, {
      withCredentials: true,
    });
  }

  // Journal Entries
  public searchJournalEntries(params: any) {
    this._loading$.next(true);

    return this.http
      .post<IResponseDTO<IJournalEntryResponse[]>>(
        `${this.apiUrl}/journal-entries/search`,
        {
          ...params,
          withCredentials: true,
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }

  public getJournalEntries(
    property: string,
    manager: string,
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<IResponseDTO<IJournalEntryResponse[]>> {
    this._loading$.next(true);
    return this.http
      .get<IResponseDTO<IJournalEntryResponse[]>>(
        `${this.apiUrl}/journal-entries/${limit}/${offset}/${sort}/${order}`,
        {
          withCredentials: true,
          headers: {
            property,
          },
        }
      )
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public getJournalEntry(
    id: string
  ): Observable<IResponse<IJournalEntryResponse>> {
    return this.http.get<IResponse<IJournalEntryResponse>>(
      `${this.apiUrl}/journal-entries/${id}`,
      { withCredentials: true }
    );
  }
  public createJournalEntry(body: IJournalEntry) {
    return this.http.post(`${this.apiUrl}/journal-entries/`, body);
  }
  public editJournalEntry(id: string, body: IJournalEntry) {
    return this.http.patch(`${this.apiUrl}/journal-entries/${id}`, body, {
      withCredentials: true,
    });
  }
  public deleteJournalEntry(id: string) {
    return this.http.delete(`${this.apiUrl}/journal-entries/${id}`, {
      withCredentials: true,
    });
  }
  public submitNewBillForIdentification(data: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/unsaved-bills/identification`, data, {
      withCredentials: true,
    });
  }
  public getUnsavedBills(): Observable<any> {
    return this.http.get(`${this.apiUrl}/unsaved-bills`, {
      withCredentials: true,
    });
  }

  public saveUnsavedBills(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/unsaved-bills/identification`, data, {
      withCredentials: true,
    });
  }

  public deleteUnsavedBills(idsToDelete: string[]): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/delete-unsaved-bills`,
      { bills: idsToDelete },
      { withCredentials: true }
    );
  }

  public saveAssociationAccounting(
    associationId: string,
    data: any
  ): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}/associations/${associationId}/accounting`,
      data,
      { withCredentials: true }
    );
  }

  public searchPayableTemplates(params: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/bank/payable-templates/search`, {
      ...params,
      withCredentials: true,
    });
  }
  public searchLockboxBatches(params: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/bank/batches/search`, {
      ...params,
      withCredentials: true,
    });
  }

  public getLockboxBatchByBatchNo(batchNo: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/bank/batches/batch/${batchNo}`, {
      withCredentials: true,
    });
  }
  public getLockboxBatchDetailsByBatchNo(batchNo: number): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/bank/batches/batch-details/${batchNo}`,
      {
        withCredentials: true,
      }
    );
  }
  public getChargebacks(
    limit: number,
    offset: number,
    sort: string,
    order: "asc" | "desc"
  ): Observable<any> {
    const body = {
      limit,
      offset,
      sort,
      order,
    };
    this._loading$.next(true);
    return this.http
      .post(`${this.apiUrl}/bank/chargebacks`, body, {
        withCredentials: true,
      })
      .pipe(tap(_ => this._loading$.next(false)));
  }
  public searchChargebacks(params: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/bank/chargebacks/search`, params, {
      withCredentials: true,
    });
  }
  public getUnpaidBills(
    propertyId: string = "",
    sort: string = "",
    order: string = ""
  ): Observable<any> {
    console.log("sort", sort, order);
    const body = {
      propertyId,
    };
    return this.http.post(`${this.apiUrl}/bills/unpaid`, body, {
      withCredentials: true,
    });
  }
}
